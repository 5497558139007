html {
  height: 100%;
  width: 100%; }

body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }


#root {
  height: 100%;
  width: 100%; }

* {
  box-sizing: border-box; }

.full-height {
  height: 100%; }

.landscape {

  &.App {
    .country-summary {
      .row {
        min-height: 22%; } } }
  @media screen and (max-width: 640px) and (max-height: 360px) {
    &.App {
      font-size: .3em; } }

  @media screen and (min-width: 640px) and (min-height: 360px) {
    &.App {
      font-size: .5em; } }

  @media screen and (min-width: 1280px) and (min-height: 720px) {
    &.App {
      font-size: 1em; } }

  @media screen and (min-width: 1920px) and (min-height: 1080px) {
    &.App {
      font-size: 1.5em; } }

  @media screen and (min-width: 2560px) and (min-height: 1440px) {
    &.App {
      font-size: 2em; } }

  @media screen and (min-width: 3840px) and (min-height: 2160px) {
    &.App {
      font-size: 3em; } } }

.squared {

  &.App {
    .country-summary {
      .row {
        min-height: 22%; } } }
  @media screen and (max-width: 360px) and (max-height: 360px) {
    &.App {
      font-size: .3em; } }

  @media screen and (min-width: 360px) and (min-height: 360px) {
    &.App {
      font-size: .5em; } }

  @media screen and (min-width: 720px) and (min-height: 720px) {
    &.App {
      font-size: 1em; } }

  @media screen and (min-width: 1080px) and (min-height: 1080px) {
    &.App {
      font-size: 1.5em; } }

  @media screen and (min-width: 1440px) and (min-height: 1440px) {
    &.App {
      font-size: 2em; } }

  @media screen and (min-width: 2160px) and (min-height: 2160px) {
    &.App {
      font-size: 3em; } }


  &.App {

    .main-panel {

      .country {
        .detail {

          .country-summary {
            width: 40%; }

          .country-chart-container {
            width: 60%; } } } } } }

.portrait {

  @media screen and (max-width: 360px) and (max-height: 640px) {
    &.App {
      font-size: .4em; } }

  @media screen and (min-width: 360px) and (min-height: 640px) {
    &.App {
      font-size: .5em; } }

  @media screen and (min-width: 720px) and (min-height: 1280px) {
    &.App {
      font-size: 1em; } }

  @media screen and (min-width: 1080px) and (min-height: 1920px) {
    &.App {
      font-size: 1.5em; } }

  @media screen and (min-width: 1440px) and (min-height: 2560px) {
    &.App {
      font-size: 2em; } }

  @media screen and (min-width: 2160px) and (min-height: 3840px) {
    &.App {
      font-size: 3em; } }


  &.App {}

  .main-panel {

    .country {
      display: block;
      height: 97%;

      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2em;
        padding: 1em 1em 0;
        height: 12%;
        span.country-title {
          border-radius: .4em; }
        span.covid {
          position: absolute;
          font-weight: 100;
          color: white;
          top: .2em;
          left: auto; } }

      .detail {
        display: block;
        height: 88%;

        .country-summary {
          width: 100%;
          height: 35%;
          justify-content: space-around;
          flex-direction: row;
          flex-wrap: wrap;

          .row {
            min-width: 40%;
            margin-bottom: 1em;
            .label {
              margin-top: 1em; }

            .value {
              font-weight: bold;
              font-size: 4em; } } }

        .country-chart-container {
          // background-color: yellow
          width: 100%;
          height: 65%;

          .confirmed {
            // background-color: blue
            // display: inline-block
            vertical-align: top;
            height: 60%;
            width: 100%; }

          .deaths {
            // background-color: red
            display: inline-block;
            vertical-align: top;
            width: 47%;
            margin-left: 0;
            margin-right: -0; }

          .recovered {
            // background-color: green
            display: inline-block;
            vertical-align: top;
            width: 47%;
            margin-left: 0;
            margin-right: 0; }

          .countries-chart {
            width: 87%; } } } } } }

