
.setup {

  .credits {
    a {
      text-decoration: none;
      color: teal !important; } }

  .line-bg {
    height: 7px;
    width: 100%;
    display: block;
    background: url(../../images/line.jpg) repeat-x; }

  header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3em;
    font-weight: 100; }

  .content {
    padding: 1em; }

  .duration {
    text-align: right;
    font-size: 1.3em;
    width: 4em; }

  .tip {
    color: #888;
    font-size: .8em;
    font-weight: 100; }

  button {
    margin: .5em .5em;
    padding: 0 1em;
 }    // border-radius: .2em

  code {
    display: flex;
    word-break: break-word;
    padding: 1em;
    font-size: 1.1em;
    justify-content: center;
    background-color: #222;
    color: white; }

  fieldset {
    margin-top: 1em;
    border: 1px solid #CCC;
    border-radius: .5em; }

  .countries {
    display: flex;
    flex-wrap: wrap;
    max-height: 28em;
    overflow-y: scroll;

    .country-check {
      display: inline-flex;
      padding: 0 1em;
      min-width: 20em;
      align-items: center;

      input {
        width: 1.5em;
        height: 1.5em;
        padding: 0;
        margin: .2em;
        border: none; }
      label {
        span.country-title {
          font-size: 1em; } } } } }

