
.main-panel {
  display: block;
  height: 100%;
  padding: 0em;
  background-image: url('../../bg.jpg');
  background-size: cover;
  overflow: hidden;

  .credits {
    // background-color: lightblue
    // background-color: rgba(255, 255, 255, .4)
    height: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .7em;

    a {
      text-decoration: none;
      color: white; } } }
